/* BickereeDetailPage.css */

/* Overall container for the page */
.bdp-container {
  background-color: #f0f4fa;
  min-height: 100vh;
  /* Keep 2rem padding top/bottom, but also center the content horizontally */
  padding: 2rem 1rem;
  font-family: sans-serif;
  box-sizing: border-box;

  /* Constrain the total width, center horizontally */
  max-width: 1200px;
  margin: 0 auto; 
}

/* Top row: includes the left profile card + green & red columns */
.bdp-header {
  display: flex;
  flex-wrap: nowrap;           /* Keep them on one row if possible */
  justify-content: space-between; /* Left card flush left, red cards flush right */
  align-items: flex-start;     /* Align top edges */
  margin-bottom: 2rem;
  gap: 2rem;                   /* Gap between columns */
}

/* The left "profile card" */
.profile-card {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 6px rgba(0,0,0,0.15);
  width: 250px;         /* Fixed width ensures consistent alignment */
  text-align: center;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Circular photo */
.profile-photo {
  width: 200px;
  height: 200px;
  object-fit: cover;
  border-radius: 50%;
  border: 5px solid #276403;
  margin-bottom: 1rem;
}

/* Name & affiliation */
.profile-name {
  font-size: 1.2rem;
  margin: 0.5rem 0 0.25rem;
}

.profile-affiliation {
  font-size: 0.9rem;
  color: #777;
  margin-bottom: 0.5rem;
  text-align: center;
}

/* Each column for green & red cards */
.cards-column {
  flex: 1 1 300px;
  max-width: 400px;
  display: flex;
  flex-direction: column;
}

.cards-column-title {
  margin: 0 0 0.5rem;
  font-size: 1rem;
  font-weight: 600;
}

.cards-list {
  /* White background */
  background-color: #fff;
  /* Fixed height + scrollbar */
  height: 252px;
  overflow-y: auto;
  padding: 1rem;
  border-radius: 6px;
  /* etc. */
}
.green-bg {
  /* If you want a subtle green tint, remove this 
     to keep it purely white: */
  background-color: #fff !important;
}
.red-bg {
  background-color: #fff !important;
}


/* Individual card inside the list */
.card-item {
  background-color: #fff;
  border-radius: 15px;
  margin-bottom: 1rem;
  padding: 1rem;
}

/* Different highlights for green/red items if you wish */
.green-card {
  background-color: #B2FFC3;
}
.red-card {
  background-color: #ffc5c5;
}

/* Meta info below card text */
.card-meta {
  font-size: 0.8rem;
  color: #666;
  margin-top: 0.25rem;
  text-align: end;
}

/* If there's nothing in the list */
.empty-state {
  font-size: 0.9rem;
  font-style: italic;
  color: #777;
}

/* Bottom row for Non-Aff, Crane, Last Word */
.bdp-bottom-row {
  display: flex;
  flex-wrap: nowrap;           /* Keep them aligned in one row if possible */
  justify-content: space-between; /* flush left + right */
  gap: 2rem;
}

/* Each single-card box in the bottom row */
.single-card-box {
  background-color: #fff;
  flex: 1 1 250px;
  max-width: 280px;
  border-radius: 6px;
  box-shadow: 0 2px 6px rgba(0,0,0,0.15);
  padding: 1rem;
  text-align: left;
}

.single-card-box h3 {
  margin-top: 0;
  font-size: 1rem;
  margin-bottom: 0.5rem;
}

.single-card-content {
  background-color: #f8f8f8;
  border-radius: 4px;
  padding: 0.75rem;
  min-height: 100px;
  max-height: 150px;
  overflow-y: auto; /* Scroll if content is too long */
}

.single-card-content p {
  margin: 0;
  margin-bottom: 0.5rem;
}

.add-card-btn,
.add-single-btn {
  background-color: #0c6eff;
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 0.4rem 0.7rem;
  margin-left: 0.5rem;
  cursor: pointer;
  font-size: 0.85rem;
}

.add-card-btn:hover,
.add-single-btn:hover {
  background-color: #0052cc;
}

/* Pencil icon inside .card-meta */
.edit-icon {
  margin-left: 0.5rem;
  color: #555;
  cursor: pointer;
}

/* single-card-box header for title + button/pencil */
.single-card-box-header {
  display: flex;
  align-items: center;
  justify-content: space-between; /* title on left, button/pencil on right */
  margin-bottom: 0.5rem;
}

/* Modal overlay */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

/* Modal content */
.modal-content {
  background: #fff;
  padding: 1rem;
  border-radius: 6px;
  width: 400px;
  max-width: 90%;
}

.modal-actions {
  display: flex;
  justify-content: flex-end;
  margin-top: 1rem;
}

.modal-actions button {
  margin-left: 0.5rem;
  padding: 0.4rem 0.8rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.new-card-modal {
  display: flex;
  flex-direction: column;
  width: 450px;           /* slightly wider */
  max-width: 90%;
  border-radius: 10px;    /* a bit rounder corners */
  padding: 0;             /* we’ll handle spacing in header/body/footer */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.25);
}

/* Header */
.modal-header {
  padding: 1rem;
  border-bottom: 1px solid #ddd;
}
.modal-title {
  margin: 0;
  font-size: 1.2rem;
  text-align: center;
}

/* Body */
.modal-body {
  padding: 1rem;
  display: flex;
  flex-direction: column;
}
.modal-label {
  margin-bottom: 0.5rem;
  font-weight: 600;
  font-size: 0.95rem;
}
.modal-textarea {
  width: 96%;
  min-height: 100px;
  resize: vertical;  /* let it expand if needed */
  padding: 0.5rem;
  font-family: inherit;
  font-size: 0.95rem;
  border-radius: 6px;
  border: 1px solid #ccc;
  outline: none;
}

/* Footer */
.modal-footer {
  padding: 0.75rem 1rem;
  border-top: 1px solid #ddd;
  display: flex;
  justify-content: flex-end;
  gap: 0.5rem;
}

/* Buttons in footer */
.modal-cancel-btn,
.modal-save-btn {
  border: none;
  cursor: pointer;
  padding: 0.45rem 0.75rem;
  border-radius: 4px;
  font-size: 0.9rem;
}

.modal-cancel-btn {
  background-color: #ccc;
  color: #333;
}
.modal-cancel-btn:hover {
  background-color: #b3b3b3;
}

.modal-save-btn {
  background-color: #276403; /* or your brand color */
  color: #fff;
}
.modal-save-btn:hover {
  background-color: #1d4e02; /* darken a bit on hover */
}

.modal-delete-btn {
  background-color: #d9534f; /* typical "danger" red */
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 0.45rem 0.75rem;
  cursor: pointer;
  font-size: 0.9rem;
  margin-right: auto; /* push it to the left if you want it left-aligned */
}

.modal-delete-btn:hover {
  background-color: #c9302c;
}
.bick-nav-btn {
  background-color: #276403;
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 0.5rem 1rem;
  font-size: 0.9rem;
  cursor: pointer;
}
.bick-nav-btn:hover {
  background-color: #1d4e02;
}
.bick-nav-btn:disabled {
  background-color: #ccc;
  cursor: not-allowed;
  color: #666;
}
