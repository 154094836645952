/* SignInPage.css */

.signin-container {
    /* Full viewport background with the same hero image (adjust path if needed) */
    width: 100%;
    height: 100vh;
    background: url("./images/CDE_building.jpg") no-repeat center center;
    background-size: cover;
    position: relative;
    display: flex;
    justify-content: center; /* center horizontally */
    align-items: center;    /* center vertically */
  }
  
  /* A dark overlay “panel” for your login content */
  .signin-overlay {
    background-color: rgba(0, 0, 0, 0.6);
    padding: 2rem;
    border-radius: 8px;
    color: #fff;
    text-align: center;
    width: 350px; /* Adjust as desired */
    max-width: 90%; /* So it’s responsive on small screens */
  }
  
  .signin-overlay h1 {
    margin-bottom: 1.5rem;
    font-size: 1.8rem;
    letter-spacing: 1px;
  }
  
  .signin-overlay button,
  .signin-overlay .google-login-button {
    /* If you want to style the Google button or any custom button */
    margin-top: 1rem;
    background-color: #006400; /* Dark green or any brand color */
    color: #fff;
    padding: 0.75rem 1.5rem;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1rem;
  }
  
  .signin-overlay button:hover,
  .signin-overlay .google-login-button:hover {
    background-color: #008000; /* A lighter green on hover */
  }
  