/* Overall container for the page: replicate the background + padding look */
.member-profile-container {
  background-color: #f0f4fa;
  min-height: 100vh;
  padding: 2rem 1rem;
  font-family: sans-serif;
  box-sizing: border-box;
  max-width: 1200px;
  margin: 0 auto; 
}

/* Header area (name & affiliation) */
.member-header {
  text-align: center;
  margin-bottom: 2rem;
}
.member-header h1 {
  margin: 0;
  font-size: 2rem;
}
.affiliation-text {
  color: #555;
  margin-top: 0.5rem;
}

/* Cards row for green & red */
.cards-row {
  display: flex;
  gap: 2rem;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 2rem;
}

/* A column for green or red card boxes */
.cards-column {
  flex: 1 1 300px;
  max-width: 500px;
  min-width: 300px;
  display: flex;
  flex-direction: column;
}

.cards-column-header {
  margin-bottom: 0.5rem;
}
.cards-list {
  background-color: #fff;
  border-radius: 6px;
  padding: 1rem;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15);

  /* If you want a fixed height with scroll, do: */
  /* height: 252px;
     overflow-y: auto; */
}

/* Subtle backgrounds for the green & red columns themselves (optional) */
.green-bg {
  background-color: #e7ffe7;
}
.red-bg {
  background-color: #ffe7e7;
}

/* Individual card item */
.card-item {
  background-color: #fff;
  border-radius: 15px;
  margin-bottom: 1rem;
  padding: 1rem;
}

/* Green / Red highlights if you want them inside the card itself */
.green-card {
  background-color: #b2ffc3;
}
.red-card {
  background-color: #ffc5c5;
}

/* text + meta info inside card */
.card-item p {
  margin: 0;
  font-size: 1rem;
  margin-bottom: 0.25rem;
}

/* The 'For: BickereeName' row + pencil icon on same line */
.card-meta {
  font-size: 0.85rem;
  color: #555;
  margin-top: 0.25rem;
  align-items: center;
  justify-content: space-between; /* pencil on right, text on left */
  gap: 0.5rem;
}

/* If there's nothing in the list */
.empty-state {
  font-size: 0.9rem;
  color: #999;
  font-style: italic;
}

/* Bottom row for single-card boxes (non-aff, crane, last word) */
.bottom-row {
  display: flex;
  gap: 2rem;
  flex-wrap: wrap;
  justify-content: center;
}

.single-card-box {
  background-color: #fff;
  flex: 1 1 300px;
  max-width: 300px;
  min-width: 200px;
  border-radius: 6px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15);
  padding: 1rem;
  text-align: left;
  display: flex;
  flex-direction: column;
}
.single-card-box h3 {
  margin-top: 0;
  font-size: 1rem;
  margin-bottom: 0.5rem;
}
.single-card-content {
  background-color: #f8f8f8;
  border-radius: 4px;
  min-height: 80px;
  padding: 0.75rem;
  flex: 1;  /* fill vertical space if you want */
}

/* Pencil icon inline with meta */
.edit-icon {
  margin-left: 0.5rem;
  color: #555;
  cursor: pointer;
}

/* ----------------------------
   Modal styling 
   ---------------------------- */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}
.modal-content {
  background: #fff;
  padding: 1rem;
  border-radius: 6px;
  width: 400px;
  max-width: 90%;
  box-shadow: 0 4px 10px rgba(0,0,0,0.25);
}
.modal-actions {
  display: flex;
  justify-content: flex-end;
  margin-top: 1rem;
  gap: 0.5rem;
}
.modal-actions button {
  border: none;
  border-radius: 4px;
  cursor: pointer;
  padding: 0.45rem 0.75rem;
  font-size: 0.9rem;
}

/* Basic “danger” delete style, etc. */
.modal-delete-btn {
  background-color: #d9534f;
  color: #fff;
}
.modal-delete-btn:hover {
  background-color: #c9302c;
}
.modal-cancel-btn {
  background-color: #ccc;
  color: #333;
}
.modal-cancel-btn:hover {
  background-color: #b3b3b3;
}
.modal-save-btn {
  background-color: #276403;
  color: #fff;
}
.modal-save-btn:hover {
  background-color: #1d4e02;
}
