/* Overall container for the page */
.profile-container {
  background-color: #f0f4fa; /* Light background from your Figma */
  min-height: 100vh;
  padding: 2rem;
  font-family: sans-serif;
  box-sizing: border-box;
  text-align: center; /* Center the heading and netid text */
}

/* Main title and netid are now centered via .profile-container { text-align: center } */
.profile-title {
  font-size: 2rem;
  margin: 0;
  margin-bottom: 0.25rem;
}

.netid-text {
  margin: 0 0 2rem;
  color: #555;
}

/* Flex container for the two cards */
.profile-cards {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  justify-content: center; /* Center the card row */
  margin: 0 auto;
  max-width: 1200px; /* If you want a max width, remove if you want them truly fluid */
}

/* Each card now flexes to use available space 
   (minimum 300px, up to 45% of the container) */
.profile-card {
  background-color: #fff;
  border-radius: 6px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15);
  flex: 1 1 300px; 
  max-width: 45%; 
  min-width: 300px;
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem; /* space in case of wrap */
}

.card-header {
  background-color: #f5f5f5;
  padding: 1rem;
  font-weight: 600;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  text-align: left; 
}

.card-body {
  padding: 1.5rem;
  text-align: left; /* keep the fields left-aligned inside */
}

/* Profile picture container */
.avatar-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;
}

/* Make the avatar larger */
.profile-avatar {
  width: 180px;
  height: 180px;
  object-fit: cover;
  border-radius: 50%;
  border: 4px solid #0c6eff;
}

.empty-avatar {
  width: 180px;
  height: 180px;
  background-color: #ccc;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #666;
}

/* Center the upload button and make it bigger */
.upload-wrapper {
  display: flex;
  justify-content: center;
}

.upload-button {
  display: inline-block;
  padding: 0.75rem 1.5rem;
  background-color: #0c6eff;
  color: #fff;
  text-align: center;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1.1rem; 
  margin: 0 auto;
}

/* Form groups for the fields on the right card */
.form-group {
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
}

.form-group label {
  font-size: 0.9rem;
  font-weight: 500;
  margin-bottom: 0.25rem;
}

.form-group input,
.form-group textarea {
  padding: 0.5rem;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
}

/* Center the save button below the cards */
.profile-actions {
  margin-top: 2rem;
}

.save-button {
  background-color: #2ecc71; /* Green color */
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 0.75rem 1.5rem;
  font-size: 1.1rem;
  cursor: pointer;
}

.save-button:hover {
  background-color: #27ae60;
}

/* Fullscreen overlay behind the modal content */
.custom-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4); /* semi-transparent black */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999; /* high z-index so it’s on top of everything */
}

/* The modal content box */
.custom-modal-content {
  background-color: #fff;
  padding: 2rem;
  border-radius: 8px;
  min-width: 300px;
  max-width: 500px;
  text-align: center;
  box-shadow: 0 2px 8px rgba(0,0,0,0.2);
}

/* The dismiss/close button in the modal */
.modal-close-button {
  margin-top: 1.5rem;
  background-color: #2ecc71; /* or any other color */
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  cursor: pointer;
}

.modal-close-button:hover {
  background-color: #27ae60;
}

