/* The main container of the nav bar */
.navBarContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #397f32;
  padding: 10px 20px;
  border-bottom: 1px solid #111;
  min-height: 60px;
}

/* Left side icons */
.navLeft {
  display: flex;
  gap: 15px; /* space between icons */
}

/* Icon link base style */
.iconLink {
  color: #fff; /* white icons/text on the green bar */
  text-decoration: none;
  display: flex; /* to center the icon or text properly */
  align-items: center;
}

/* Disabled link styling (when loading) */
.disabledLink {
  opacity: 0.5;
  cursor: not-allowed;
}

/* Center title */
.navCenter h1 {
  margin: 0;
  font-size: 1.5rem;
  color: #fff;
}

/* Right side: search container */
.navRight {
  display: flex;
  align-items: center;
}

.searchContainer {
  position: relative;
}

.searchInput {
  padding: 6px 8px;
  border: 1px solid #111;
  border-radius: 4px;
  outline: none;
  font-size: 1rem;
}

/* If disabled, we can show some style: this is optional */
.searchInput:disabled {
  background-color: #ddd;
  cursor: not-allowed;
}

/* The dropdown below the input */
.searchDropdown {
  position: absolute;
  top: 38px;
  left: 0;
  width: 100%;
  background-color: #fff;
  border: 1px solid #111;
  border-radius: 4px;
  list-style: none;
  margin: 0;
  padding: 0;
  z-index: 999;
}

.searchDropdownItem {
  padding: 8px 10px;
  cursor: pointer;
  color: #111;
}

.searchDropdownItem:hover {
  background-color: #f2f2f2;
}
