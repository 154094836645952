/* frontend/src/pages/Home.css */

/* 
   Container that fills the viewport with the background image.
   Update the `url(...)` path to match wherever you store CDE_building.jpg.
*/
.home-container {
    width: 100%;
    height: 100vh; /* full viewport height */
    background: url("./images/CDE_building.jpg") no-repeat center center;
    background-size: cover;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  /* 
     Optional overlay to slightly darken the background, if desired.
     You can remove this div and style if you’d rather not have an overlay. 
  */
  .home-overlay {
    background-color: rgba(0, 0, 0, 0.4); /* semi-transparent black overlay */
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  /* Large page title */
  .club-title {
    color: #ffffff;
    font-size: 3rem;
    text-align: center;
    margin-bottom: 1em;
    letter-spacing: 2px;
  }
  
  /* “Get Started” button styling */
  .get-started-button {
    background-color: #006400;
    color: #ffffff;
    padding: 0.75em 1.5em;
    font-size: 1.2rem;
    border-radius: 5px;
    text-decoration: none;
    transition: background-color 0.2s ease;
  }
  
  .get-started-button:hover {
    background-color: #008000;
  }
  